<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="返品伝票登録" />
      <v-form v-model="isInputCheck" ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <div v-if="suppliersSelected.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        dense
                        readonly
                        v-model="suppliersSelected"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single"
                        persistent-hint
                        margin-bottom="0"
                        pa-3
                      ></v-text-field>
                    </div>
                  </template>
                  <span>{{ suppliersSelected }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    dense
                    readonly
                    v-model="suppliersSelected"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single"
                    persistent-hint
                    margin-bottom="0"
                    pa-3
                  ></v-text-field>
                </div>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height: 20px" v-bind="attrs" v-on="on">
                    <v-text-field
                      readonly
                      dense
                      v-model="shippingSelectedName"
                      :items="shippingList"
                      :label="$t('label.lbl_shippingTo')"
                      :hint="setSrhArrival()"
                      class="txt-single"
                      persistent-hint
                    >
                    </v-text-field>
                  </div>
                </template>
                <span>{{ shippingSelectedName }} </span>
              </v-tooltip>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 元出荷No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                readonly
                dense
                v-model="txt_slipNo"
                maxlength="50"
                :label="$t('label.lbl_OriginalshippingNo')"
                class="txt-single"
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 元出荷日-->
            <div class="date-style">
              <v-text-field
                readonly
                outlined
                dense
                v-model="originalShippingDate"
                :label="$t('label.lbl_OriginalshipDate')"
                class="txt-single date-style"
              ></v-text-field>
            </div>

            <span class="item-spacer">&nbsp;</span>
            <!-- 返品伝票No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="txt_Return_slipNo"
                maxlength="45"
                :label="$t('label.lbl_returnSlipNo')"
                class="txt-single"
                :rules="[rules.inputRequired]"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有償無償-->
            <div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="isPaid"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in freeCostDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 無償理由選択プルダウン-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                class="list-single"
                v-model="freeReasonSelected"
                :items="freeReasonList"
                v-bind:disabled="isDisabledFreeReason"
                :label="$t('label.lbl_freeReason')"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <!-- 返品日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippingAchieveDateDateFrom"
                :label="$t('label.lbl_returnDate')"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertReturnMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="shippingAchieveDateDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷品質区分-->
            <div class="search-autocomplete">
              <v-text-field
                readonly
                dense
                v-model="arrivalStatusSelected"
                :label="$t('label.lbl_shippingArrivalStatus')"
                class="txt-single"
                persistent-hint
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 返品品質区分-->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                v-model="rtnProdStatus"
                :items="rtnProdStatusList"
                :label="$t('label.lbl_returnArrivalStatus')"
                class="txt-single"
                persistent-hint
                :hint="setRtnStatus()"
                :rules="[rules.inputRequired]"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 責任区分-->
            <v-btn-toggle
              v-model="blame"
              mandatory
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="openTruck">{{
                  $t("出荷実績検索")
                }}</v-btn>
              </div>
              <div class="btn-area">
                <!--登録ボタン-->
                <v-btn color="primary" class="api-btn" @click="confirmPost()">{{
                  $t("btn.btn_insert")
                }}</v-btn>
                <!--動作確認仮登録ボタン-->
                <!-- <v-btn color="primary" class="api-btn" @click="apiPostTest()">{{
                  $t("動作確認仮登録")
                }}</v-btn> -->
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-form ref="editList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :ripple="false"
            :server-items-length="itemsPerPage"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="530px"
            :options.sync="sortOptions"
          >
            <!-- ヘッダーロット -->
            <template v-slot:[`header.lot`]="{ header }">
              <span>
                <div>{{ header.text }}</div>
                <span>
                  {{
                    $t("label.lbl_dueDate") +
                    "／" +
                    $t("label.lbl_lotSubkey1") +
                    "／" +
                    $t("label.lbl_lotSubkey2")
                  }}
                </span>
              </span>
            </template>
            <!-- TODO: 出荷先の表示をここに記載する。（コード（名称）） -->
            <template v-slot:[`item.shippngCdNm`]="{ item }">
              <div v-if="`item.shippngCdNm.length` > 18">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.shippngCdNm.substring(0, 18) + "..." }}
                    </div>
                  </template>
                  <div>{{ item.shippngCdNm }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                <div>{{ item.shippngCdNm }}</div>
              </div>
            </template>
            <!-- ヘッダーチェックボックス -->
            <template v-slot:[`header.check`]="{ header }">
              <input
                type="checkbox"
                :checked="isAllSelected"
                :value="header.check"
                @click="selectAllCheck"
                style="transform: scale(2)"
              />
            </template>
            <!-- 一覧チェックボックス -->
            <template v-slot:[`item.check`]="{ item, index }">
              <input
                type="checkbox"
                v-model="item.check"
                :value="listCheckbox"
                @click="checkList(item, index)"
                style="transform: scale(2)"
              />
            </template>
            <!-- 商品コード・品名 -->
            <template v-slot:[`item.productCn`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 380px" v-bind="attrs" v-on="on">
                    {{ item.productCn }}<br />
                    {{ item.incidental }}<br />
                    {{ item.productName }}
                  </div>
                </template>
                <span
                  >{{ item.productCn }}<br />
                  {{ item.incidental }}<br />
                  {{ item.productName }}</span
                >
              </v-tooltip>
            </template>

            <!-- ロット・S/N -->
            <template v-slot:[`item.lot`]="{ item }">
              <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="width: 370px" v-bind="attrs" v-on="on">
                      {{ item.lot }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.lot }}<br />
                {{ item.dueDate }}
              </div>
            </template>

            <!-- 返品理由区分-->
            <template v-slot:[`item.returnReason`]="{ item }">
              <div id="table-productCd">
                <v-autocomplete
                  v-model="item.returnReason"
                  :items="reasonList"
                  persistent-hint
                  :rules="[rules.inputRequired]"
                  disable-sort
                  dense
                  class="cursorProcd"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>
            </template>
            <!-- 返品数量-->
            <template v-slot:[`item.returnQty`]="{ item, index }">
              <div id="table-productQty">
                <v-text-field
                  class="input-number"
                  dense
                  outlined
                  v-model="item.returnQty"
                  @change="changeReturnQty(item, index)"
                  @click="clickPop(item.returnQty)"
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'productQtyCheck' + index"
                />
              </div>
            </template>
            <!-- 備考欄-->
            <template v-slot:[`item.remarks`]="{ item }">
              <div id="table-productQty">
                <v-text-field
                  class="text-box"
                  dense
                  outlined
                  v-model="item.remarks"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[rules.inputRequired, rules.limitNumLength200]"
                />
              </div>
            </template>
            <!-- 削除-->
            <template v-slot:[`item.deleteData`]="{ item }">
              <v-btn small @click="deleteItem(item)" text>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :secondPageFlag="infoDialog.secondPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
        :rtnapprovalFlg="infoDialog.rtnapprovalFlg"
        :rtnAddFlg="infoDialog.rtnAddFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />

      <!-- 出荷実績登録 ダイアログ表示 -->
      <v-dialog v-model="isOpenTruck" persistent no-click-animation>
        <ShipScheduleList
          :isOpenDetailDgialog.sync="isOpenTruck"
          @click="saveTruck"
          v-on:emitSelect="emitConsole"
          v-on:closePopup="closePopup"
          :isOpenprop="isOpenprop"
        />
      </v-dialog>
      <!-- 登録確認 -->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="postReturnSlip"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import ShipScheduleList from "../ship/ShipAchieveSearch.vue"; //出荷実績登録に変更（現状は仮で出荷予定一覧）
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "../../components/ConfirmDialog";

export default {
  name: "ReturnSlipAdd",
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
    ShipScheduleList, //出荷実績登録
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    backMessage: "",
    txt_slipNo: "",
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 入力チェック結果
    isInputCheck: false,
    sortItem: "",
    sortOptions: {},
    u_10: false,
    involce: "",
    // 輸送会社ダイアログ
    isOpenTruck: false,
    // 輸送会社データ項目
    truckInfo: { truck: "" },

    // ロケーションダイアログ
    dialogLocation: false,
    // 出荷予定日From
    shippingAchieveDateDateFromCal: "",
    shippingAchieveDateDateFrom: "",
    //元出荷日
    originalShippingDate: "",
    // 出荷予定日To
    shippingAchieveDateDateToCal: "",
    shippingAchieveDateDateTo: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 出荷先
    shippingSelected: "",
    // 出荷先名
    shippingSelectedName: "",
    // 取引先
    suppliersSelected: "",
    // 発行条件(送り状)
    issueTermsSelected: "",
    // 発行条件(荷札)
    tagTermsSelected: "",
    // メニュー
    openMenu: null,
    // 出荷予定Fromカレンダー
    dateMenuFrom: false,
    // 出荷予定Toカレンダー
    dateMenuTo: false,
    blameDivList: [],
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    listCheckboxBtn: [],
    // 有償/無償
    isPaid: "01",
    freeCostDivList: [],
    // 無償理由プルダウン
    freeReasonList: [],
    freeReasonSelected: "",
    isDisabledFreeReason: true,
    // API用clientSid
    suppliersNm: "",
    // 返品伝票No
    txt_Return_slipNo: "",
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      secondPageFlag: false,
      thirdPageFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // 登録ボタン押下時確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    alertReturnMessage: "",
    // 責任区分
    blame: "02",
    blameBadValue: "01",
    // 出荷実績検索に渡すフラグ（初期化するため）
    isOpenprop: "",
    // ヘッダ
    headerItems: [
      { text: "選択", value: "check", width: "5%", align: "center", sortable: false },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCn",
        width: "20%",
        align: "left",
        sortable: true,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "20%",
        align: "left",
        sortable: true,
      },
      // 出荷実績数
      {
        text: i18n.tc("label.lbl_shipAchieveQty"),
        value: "shipAchieveQty",
        width: "10%",
        align: "right",
        sortable: true,
      },
      // 返品理由区分
      {
        text: i18n.tc("返品理由区分"),
        value: "returnReason",
        width: "10%",
        align: "left",
        sortable: true,
        class: "asta",
      },
      // 返品数量
      {
        text: i18n.tc("返品数量"),
        value: "returnQty",
        width: "10%",
        align: "left",
        sortable: true,
        class: "asta",
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarksTextbox"),
        value: "remarks",
        width: "20%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 削除
      {
        text: i18n.tc("btn.btn_delete"),
        value: "deleteData",
        width: "5%",
        align: "center",
        sortable: false,
      },
    ],
    // 修正削除画面
    detailDialogData: {},
    // 総件数
    totalCount: 0,
    // 一覧
    inputList: [],
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shippingList: [],
    // 発行条件プルダウン
    issueTermsList: [],
    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    // 路線業者SID（子画面から連携されるデータ）
    deliveryTraderSid: "",
    // 路線業者名（子画面から連携されるデータ）
    deliveryTraderName: "",
    // emitのテスト
    emitSelect: "",
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    // 一覧index
    editedIndex: -1,
    // 出荷先API用Sid
    shippingNm: "",
    // ダイアログ
    updateDialogMessage: "",
    // 無償理由CD一時保存場所
    reasonCdChe: "",
    // 返品理由区分
    reasonList: [],
    // 出荷品質区分
    arrivalStatusSelected: "",
    // 返品品質区分
    rtnProdStatus: "",
    rtnProdStatusList: [],
    // 責任区分非活性
    isDisabledBlame: true,

    // 入力チェック
    rules: {
      // 取引先未入力チェック
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      // 未入力チェック
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      // 日付形式チェック(yyyy/MM/dd)
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      limitNumLength: (value) =>
        String(value).replaceAll(",", "").length < 10 || i18n.tc("check.chk_limitNumLength"), // 文字数の制約
      limitNumLength200: (value) =>
        value == null || value.length <= 200 || i18n.tc("check.chk_limitLength_1-200"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheck: (value) =>
        0 < Number(commonUtil.zen2han(value).replaceAll(",", "")) || i18n.tc("check.chk_inputMore"),
    },
  }),

  methods: {
    emitConsole(data) {
      this.emitSelect = data;
      this.isOpenTruck = false;
      this.suppliersSelected = data.suppliersSelected;
      // 取引先SID
      this.suppliersNm = data.suppliersNm;
      this.shippingNm = data.shippingNm;
      this.txt_slipNo = data.shipNo;
      this.shippingSelected = data.toSid;
      this.arrivalStatusSelected = data.status;
      this.officeSid = data.officeSid;
      if (data.shippingScheduleDateJ[0] != null) {
        this.originalShippingDate = data.shippingScheduleDateJ[0];
      }
      this.getCustomInfo();
      if (data.clientName != undefined) {
        for (var j = 0; j < data.clientName.length; j++) {
          let lotNoObj = commonUtil.convertLotNo(data.lot[j]);
          let inputListItem = {};
          inputListItem.lot = lotNoObj.lot;
          inputListItem.dueDate = lotNoObj.dueDate;
          inputListItem.check = "";
          inputListItem.returnQty = "";
          inputListItem.remarks = "";
          inputListItem.deleteData = "";
          inputListItem.returnReason = "";
          inputListItem.productCn = data.productCn[j].substring(0, 50).trimRight();
          inputListItem.incidental = data.productCn[j].slice(-50).trimRight();
          inputListItem.productName = data.productNameList[j];
          inputListItem.outListSid = data.outListSid[j];
          inputListItem.outListSubSid = data.outListSubSid[j];
          inputListItem.shipAchieveQty = data.shipAchieveQty[j];
          inputListItem.locationSid = data.locationSid[j];
          inputListItem.opeHistSid = data.opeHistSid[j];
          inputListItem.managementNo = data.managementNo[j];
          inputListItem.shippingScheduleDateJ = data.shippingScheduleDateJ[j];
          this.inputList.push(inputListItem);
          inputListItem = {};
        }
      }
      this.totalCount = this.inputList.length;
    },
    /**
     * 出荷実績検索閉じるボタン押下時
     */
    closePopup() {
      this.isOpenTruck = false;
    },
    link() {
      location.href = this.returnURL;
    },

    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
    },

    /**
     * 取引先一覧と出荷先一覧を取得します。
     */
    getCustomInfo(jstOutScheduleDate) {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // 取引先一覧を取得します。
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstOutScheduleDate)
      );
      // 非同期処理を開始します。
      Promise.all([clientList])
        // 成功時の処理です。
        .then((result) => {
          // ヘッダー部の取引先一覧を設定します。
          this.supplierList = result[0];
          // 取引先一覧に対する繰り返し処理です。
          for (let i = 0; i < this.supplierList.length; i++) {
            // 選択された取引先と一致する場合の処理です
            if (this.suppliersNm == this.supplierList[i].value) {
              // 選択された取引先に取引先一覧の要素を設定します。
              this.suppliersSelected = this.supplierList[i].text;
            }
          }

          // 出荷先一覧を取得します。
          this.getSuppliersSelected(this.suppliersSelected);
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.simpleDialog = true;

          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {
          // ローディング画面の表示をOFFにします。
          this.loadingCounter = 0;
        });
    },
    /**
     * 出荷先一覧を取得します。
     */
    getSuppliersSelected() {
      // 選択された取引先のSIDを取得します。
      let officeCd = this.supplierList.find(
        (element) => element.value === this.suppliersNm
      ).clientSid;

      // 出荷先一覧を取得します。
      const toList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.FROMTO,
        dateTimeHelper.convertUTC(this.arrivalScheduleDate),
        undefined,
        officeCd
      );

      // 非同期処理を開始します。
      Promise.all([toList])
        // 成功時の処理です。
        .then((result) => {
          // ヘッダー部の出荷先一覧を設定します。
          this.shpList = result[0];

          // 出荷先一覧に対する繰り返し処理です。
          for (let i = 0; i < this.shpList.length; i++) {
            // 出荷先SIDが一致する場合の処理です。
            if (this.shippingSelected == this.shpList[i].value) {
              // 出荷先に出荷先一覧の要素を設定します。
              this.shippingSelectedName = this.shpList[i].displayText;
            }
          }
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {
          // ローディング画面の表示をOFFにします。
          this.loadingCounter = 0;
        });
    },
    /**
     * マスタ情報取得処理
     * 発行条件（送り状・荷札）を取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      Promise.all([qualityList, reasonList, processList, freeCostList, blameList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.rtnProdStatusList = result[0];
          this.freeReasonList = result[1];
          this.processDivList = result[2];
          this.freeCostDivList = result[3];
          // 責任区分の最初のなしを削除
          result[4].splice(0, 1);
          this.blameDivList = result[4];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;

      // 画面表示用文言編集
      if (this.truckInfo.deliveryTraderName) {
        // 路線業者名が選択されている場合（輸送会社区分（路線業者名））
        this.truckNm =
          this.truckInfo.carrierCompName + "（" + this.truckInfo.deliveryTraderName + "）";
      } else {
        // その他の場合は輸送会社区分を表示
        this.truckNm = this.truckInfo.carrierCompName;
      }

      // API検索用
      // 輸送会社区分
      this.truckKbn = this.truckInfo.carrierCompCd;
      // 路線業者SID
      this.deliveryTraderSid = this.truckInfo.deliveryTraderSid;
      // 路線業者名
      this.deliveryTraderName = this.truckInfo.deliveryTraderName;

      // モーダルを閉じる
      this.isOpenTruck = false;
    },

    apiPostTest() {
      if (this.$refs.form.validate()) {
        var checkTestList = [];
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check) {
            var data = this.inputList[i];
            var returnNm = this.inputList[i].returnQty.replaceAll(",", "");

            this.inputList[i].returnQty = returnNm;
            checkTestList.push(data);
          }
        }
        //チェックボックスバリデーション
        if (checkTestList.length > 0) {
          //明細入力チェック
          for (var k = 0; k < checkTestList.length; k++) {
            if (checkTestList[k].returnQty == 0) {
              // 入荷予定数が0の場合、警告メッセージ表示
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-RTN-001_001_E");
            }
          }
          //返品理由区分チェック
          for (var l = 0; l < checkTestList.length; l++) {
            if (checkTestList[l].returnReason == "") {
              // 返品理由区分が未選択の場合、警告メッセージ表示
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-RTN-002_001_E");
            } else {
              // 処理なし
            }
          }
          var headerItems = [];
          var headerItem = {
            clientSid: this.suppliersNm,
            shippingSid: this.shippingNm,
            slipNo: this.txt_slipNo,
            returnSlipNo: this.txt_Return_slipNo,
            blameDiv: this.blame,
          };
          headerItems.push(headerItem);
          this.infoDialog.isOpen = true;
        } else {
          // 警告ダイアログ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        }
      } else {
        // 伝票部、入力チェックエラーの場合、処理なし
      }
    },
    // 確認ダイアログ
    confirmPost() {
      this.alertReturnMessage = "";
      if (this.$refs.form.validate()) {
        // チェックがない場合
        var checkLists = [];
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check) {
            var data = this.inputList[i];
            checkLists.push(data);
          }
        }

        for (var j = 0; j < checkLists.length; j++) {
          // 出荷実績数よりも、返品数量が多い場合
          if (
            Number(checkLists[j].returnQty.replaceAll(",", "")) >
            Number(checkLists[j].shipAchieveQty.replaceAll(",", ""))
          ) {
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RTN-001_002_E", [
              i18n.tc("btn.btn_insert"),
            ]);
            this.loadingCounter = 0;
            return;
          }
          //返品日が出荷実績日よりも過去の場合
          if (this.shippingAchieveDateDateFrom < checkLists[j].shippingScheduleDateJ) {
            this.alertReturnMessage = i18n.tc("check.chk_inputReturnValidate");
            this.loadingCounter = 0;
            return;
          }
        }

        if (checkLists.length == 0) {
          // 警告ダイアログ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
          this.loadingCounter = 0;
        } else {
          if (this.$refs.editList.validate()) {
            this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
            this.ConfirmDialog.isOpen = true;
            this.ConfirmDialog.screenFlag = true;
            this.ConfirmDialog.changeFlag = false;
            this.infoDialog.firstPageFlag = true;
          }
        }
      }
    },
    /**
     * 返品伝票登録API（POST）
     */
    postReturnSlip() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RTN_001;

        var checkLists = [];
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check) {
            var data = this.inputList[i];
            var returnNm = this.inputList[i].returnQty.replaceAll(",", "");

            this.inputList[i].returnQty = returnNm;
            checkLists.push(data);
          }
        }
        if (checkLists.length == 0) {
          // 警告ダイアログ表示
          this.updateDialogMessage = true;
          this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
          this.loadingCounter = 0;
        } else {
          var returns = [];
          for (var j = 0; j < checkLists.length; j++) {
            var inputMap = {};
            // 返品伝票No
            inputMap.returnsNo = this.txt_Return_slipNo;
            // 出荷伝票明細SID outListSubSid
            inputMap.outListSubSid = "";

            // 出荷伝票SID outListSid
            inputMap.outListSid = checkLists[j].outListSid;

            // 取引先SID
            inputMap.clientSid = this.suppliersNm;
            // ToSID
            inputMap.toSid = this.shippingSelected;
            // 責任区分
            inputMap.blameDiv = this.blame;
            // 理由区分
            inputMap.reasonDiv = checkLists[j].returnReason;

            // 出荷伝票SID
            inputMap.outListSid = checkLists[j].outListSid;
            // 出荷伝票明細SID
            inputMap.outListSubSid = checkLists[j].outListSubSid;
            // 返品数量
            inputMap.returnsQuantity = checkLists[j].returnQty;
            // 返品日
            inputMap.returnsDate = dateTimeHelper.convertUTC(this.shippingAchieveDateDateFrom);
            // 削除フラグ
            inputMap.deleteFlg = "0";
            // 備考
            inputMap.remarks = checkLists[j].remarks;
            // 返品品質区分
            inputMap.qualityDiv = this.rtnProdStatus;
            // 営業所SID
            inputMap.officeSid = this.officeSid;
            //有償無償区分
            inputMap.freeCostDiv = this.isPaid;
            // 理由CD
            inputMap.reasonCd = this.freeReasonSelected;
            // 操作履歴SID
            inputMap.opeHistSid = checkLists[j].opeHistSid;
            //管理No
            inputMap.itemManageNo = checkLists[j].managementNo;
            //ロケーションSid
            inputMap.locationSid = checkLists[j].locationSid;
            returns.push(inputMap);
          }
          // ボディ部リスト
          body.reqIdv.returns = returns;
          // console.debug("postReturnSlip() Body", body);

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.BIZ_RETURNS, body, appConfig.APP_CONFIG)
              .then((response) => {
                // console.debug("postReturnSlip() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.outsideClickNotCloseFlg = true;
                  // 画面遷移ボタン
                  this.infoDialog.rtnAddFlg = true;
                  this.infoDialog.firstPageFlag = false;

                  // エラー時
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },
    /**
     * 警告ダイアログ、OKボタン押下
     */
    dialogOk() {
      this.updateDialogMessage = false;
    },
    /**
     * 返品数量、入力カンマ処理
     */
    changeReturnQty(val, index) {
      var returnQty = commonUtil.zen2han(val.returnQty).replaceAll(",", "");
      returnQty = returnQty.replace(/[^0-9]/gi, "");
      if (returnQty.length > 9) {
        returnQty = returnQty.substring(0, 9);
      }
      if (!isNaN(Number(returnQty))) {
        this.inputList[index].returnQty = commonUtil.formatToCurrency(Number(returnQty));
      }
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    /**
     * 初期値
     */
    init() {
      // 画面遷移の判定
      if (this.$route.query.searchSuppliersSelected) {
        // 修正削除画面から遷移する場合、前画面からの値を引き継いで画面表示させる
        this.suppliersSelected = this.$route.params.searchSuppliersSelected;
        this.shippingSelectedName = this.$route.params.searchShippingSelected;
        this.shippingAchieveDateDateFrom = this.$route.params.searchshippingAchieveDateDateFrom;
        this.$route.params.searchshippingAchieveDateDateFromCal;
        this.shippingAchieveDateDateTo = this.$route.params.searchshippingAchieveDateDateTo;
        this.shippingAchieveDateDateToCal = this.$route.params.searchshippingAchieveDateDateToCal;
        this.issueTermsSelected = this.$route.params.searchIssueTermsSelected;
        this.tagTermsSelected = this.$route.params.searchTagTermsSelected;
        this.truckNm = this.$route.params.searchTruckNm;
        // 前画面の値を基に検索を実行する。
      } else {
        // 上記以外の場合（初期表示・メニューからの遷移）
        this.shippingSelectedName = "";
        this.suppliersSelected = "";
        this.shippingAchieveDateDate = "";
        this.truckNm = "";
        this.inputList = [];
        this.itemsPerPage = 10;
        this.itemsPerPageBtn = "10";
        this.shippingAchieveDateDateTo = dateTimeHelper.convertJST();
        this.shippingAchieveDateDateFrom = dateTimeHelper.convertJST();
        this.shippingAchieveDateDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.shippingAchieveDateDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      // 初期データ取得
      this.getCustomInfo();
      // マスタ情報取得
      this.getMstCode();
      // 返品理由区分
      this.getReturnReason();
    },

    /**
     * 出荷予定一覧画面：全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.listCheckboxBtn.push(this.inputList[i]);
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
      }
    },
    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合
        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);
      } else {
        // チェックを外した場合

        // 外したレコードの出荷SIDを取得
        let inputListOutlistNo = this.inputList[index].shipSid;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].shipSid != inputListOutlistNo) {
            this.listCheckboxBtn.push(this.chengeListCheckbox[i]);
          }
        }
        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }
      }
    },
    /**
     * 返品理由区分_コードマスタAPI(GET)
     */
    getReturnReason() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 検索条件などのパラメータを設定する
      config.params.isMst = "0";
      config.params.codeType = "0701";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_CODE, config)
          .then((response) => {
            // console.debug("getReturnReason() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.codeDefinition.forEach((row) => {
                list.push({
                  text: row.codeInfoList[0].codeName,
                  value: row.code,
                  name: row.codeInfoList[0].codeName,
                });
              });
              this.reasonList = list;
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              // this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);

              // // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getReturnReason() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      // this.deleteList.push(this.inputList[this.editedIndex]);
      this.inputList.splice(this.editedIndex, 1);

      // 削除後、No修正。
      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 出荷予定一覧画面：配送先選択ボタン処理
     */
    openTruck() {
      this.isOpenprop = "dsds";
      this.isOpenTruck = true;
      this.clearAll();
    },

    clearAll() {
      this.inputList.splice(0);
      this.totalCount = 0;
    },
    /**
     * 出荷日付Fromを－１日します。
     */
    prevDate() {
      if (this.shippingAchieveDateDateFromCal == null) {
        return;
      }
      let date = new Date(this.shippingAchieveDateDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingAchieveDateDateFromCal != toDate) {
        this.shippingAchieveDateDateFromCal = toDate;
      } else {
        this.shippingAchieveDateDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() - 1)
        );
      }
    },

    /**
     * 出荷日付Fromを＋１日します。
     */
    nextDate() {
      if (this.shippingAchieveDateDateFromCal == null) {
        return;
      }
      let date = new Date(this.shippingAchieveDateDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shippingAchieveDateDateFromCal != toDate) {
        this.shippingAchieveDateDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 2)
        );
      } else {
        this.shippingAchieveDateDateFromCal = dateTimeHelper.dateCalc(
          date.setDate(date.getDate() + 1)
        );
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingAchieveDateDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingAchieveDateDateFromCal = null;
      }
    },

    /**
     * 出荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 出荷予定登録画面：出荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      for (var i = 0; i < this.shippingList.length; i++) {
        if (this.shippingList[i].value == this.shippingSelected) {
          return this.shippingList[i].name;
        }
      }
    },

    /**
     * 出荷予定登録画面：チェックボックス取得処理
     */
    onInputCheck(item) {
      if (item.check) {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].value = true;
        }
      } else {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = false;
          this.inputList[i].value = false;
        }
      }
    },

    /**
     * 返品品質区分セット
     */
    setRtnStatus() {
      for (var i = 0; i < this.rtnProdStatusList.length; i++) {
        if (this.rtnProdStatusList[i].value == this.rtnProdStatus) {
          return this.rtnProdStatusList[i].name;
        }
      }
    },

    /**
     * 返品伝票登録画面：責任区別活性化処理
     */
    activateButton() {
      if (this.rtnProdStatus == "") {
        return true;
      } else if (this.rtnProdStatus == "01") {
        return true;
      } else if (this.rtnProdStatus == "02") {
        return false;
      }
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    //有償無償変更時
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },

    dialog(val) {
      val || this.close();
    },
    shippingAchieveDateDateFromCal(val) {
      this.shippingAchieveDateDateFrom = this.formatDate(val);
    },
    shippingAchieveDateDateToCal(val) {
      this.shippingAchieveDateDateTo = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
    },
    rtnProdStatus: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.blame = this.blameBadValue;
      } else {
        this.isDisabledBlame = true;
        this.blameBadValue = this.blame;
        this.blame = "01";
      }
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          if (sortBy == "shipNo") {
            sortBy = "outListNo";
          } else if (sortBy == "shippingAchieveDateDate") {
            sortBy = "outScheduleDate";
          }
          this.sortItem = sortBy + " " + ascdesc;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

.link {
  text-decoration: none;
}
</style>
