<!-- 出荷実績登録画面ができるまでの仮画面 -->
<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <div>
        <v-app-bar id="sub-bar" dense elevation="2">
          <div id="lbl-screen-name">出荷実績検索</div>
          <!-- <div id="lbl-screen-name">{{ $t("label.lbl_arrivalScheduleList") }}</div> -->

          <v-spacer></v-spacer>

          <v-spacer></v-spacer>
        </v-app-bar>
      </div>

      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                :error-messages="alertSupplierMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shippingSelected"
                :items="shippingList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_shippingTo')"
                :hint="setSrhArrival()"
                class="txt-single"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getSuppliersSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 出荷No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="txt_slipNo"
                maxlength="50"
                :label="$t('label.lbl_shippingNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 品番・品名 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="ProdNameSelected"
                :items="prodNameList"
                @change="(event) => changeProductNm(event)"
                :hint="setProducts()"
                :search-input.sync="search"
                :label="$t('label.lbl_productCnCd')"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row :style="destinatStyle">
            <span class="item-spacer">&nbsp;</span>
            <!-- 届け先-->
            <div class="search-textbox-remark" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="destinationDisplay"
                maxlength="100"
                :label="$t('label.lbl_deliveryname')"
                class="txt-single"
                clear-icon="mdi-close-circle"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row>
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->

            <!-- 出荷予定日From-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippingScheduleDateFrom"
                :label="$t('label.lbl_scheduleDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd, rules.inputRequired]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small><v-icon>mdi-calendar</v-icon></v-btn>
                </template>
                <v-date-picker
                  v-model="shippingScheduleDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 出荷予定日To-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="shippingScheduleDateTo"
                :label="$t('label.lbl_scheduleDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd, rules.inputRequired]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>
            <span class="require">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small><v-icon>mdi-calendar</v-icon></v-btn>
                </template>
                <v-date-picker
                  v-model="shippingScheduleDateToCal"
                  @input="dateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getApiList(true, true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!-- 選択ボタン -->
                <v-btn color="primary" class="api-btn" @click="emitSelect">{{ $t("選択") }}</v-btn>
                <!-- 動作確認の為の仮検索ボタン:APIが出来次第削除 -->
                <!-- <v-btn color="primary" class="api-btn" @click="getDummyApiList(true)">{{
                  $t("検索動作確認")
                }}</v-btn> -->
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          disable-sort
          :hide-default-footer="true"
          height="530px"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーロット -->
          <template v-slot:[`header.lot`]="{ header }">
            <span>
              <div>{{ header.text }}</div>
              <span>
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </span>
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <input
              type="checkbox"
              v-model="item.check"
              :checked="item.check"
              @click="checkList(item, $event)"
              style="transform: scale(2)"
            />
          </template>
          <!-- 出荷先 -->
          <template v-slot:[`item.shippingTo`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 265px" v-bind="attrs" v-on="on">
                  {{ item.shippingTo }}<br />
                  <b style="font-weight: normal; font-size: medium"> {{ item.shipToAddress }}</b>
                </div>
              </template>
              <span
                >{{ item.shippingTo }}<br />
                {{ item.shipToAddress }}</span
              >
            </v-tooltip>
          </template>

          <!-- 出荷No -->
          <template v-slot:[`item.shipNo`]="{ item }">
            <div class="listNo-style">{{ item.shipNo }}</div>
          </template>

          <template v-slot:[`item.productCd`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 250px" v-bind="attrs" v-on="on">
                  {{ item.itemCd }}<br />
                  {{ item.incidental }}<br />
                  {{ item.productNm }}
                </div>
              </template>
              <span
                >{{ item.itemCd }}<br />
                {{ item.incidental }}<br />
                {{ item.productNm }}
              </span>
            </v-tooltip>
          </template>

          <!-- ロット -->
          <template v-slot:[`item.lot`]="{ item }">
            <div v-if="item.lotNo.length > 15 || item.dueDate.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="width: 275px" v-bind="attrs" v-on="on">
                    {{ item.lotNo }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <div>{{ item.lotNo }}<br />{{ item.dueDate }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.lotNo }}<br />
              {{ item.dueDate }}
            </div>
          </template>
        </v-data-table>
        <v-btn color="primary" class="api-btn" @click="closeDialog">
          {{ $t("btn.btn_close") }}
        </v-btn>
        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />

      <!-- トラック検索モーダル -->
      <v-dialog v-model="isOpenTruck">
        <Truck :isOpenDetailDgialog.sync="isOpenTruck" @click="saveTruck" :truckInfo="truckInfo" />
      </v-dialog>

      <!-- チェックボックスダイアログ -->
      <v-dialog v-model="selectDialogMsg" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ selectMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import Truck from "../common/Truck";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: appConfig.SCREEN_ID.P_SHP_003,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    Truck,
  },
  props: {
    isMenu: { type: Boolean, default: false },
    clientprop: {}, //返品伝票登録から受け取る値
    isOpenprop: {}, //初期表示時に初期化するための値
  },
  data: () => ({
    chengeListCheckbox: [],

    // 画面遷移用リスト
    listCheckboxBtn: [],
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    u_10: false,
    involce: "",
    // 輸送会社ダイアログ
    isOpenTruck: false,
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 輸送会社データ項目
    truckInfo: { truck: "" },
    // ロケーションダイアログ
    dialogLocation: false,
    // 出荷予定日From
    shippingScheduleDateFromCal: "",
    shippingScheduleDateFrom: "",
    // 出荷予定日To
    shippingScheduleDateToCal: "",
    shippingScheduleDateTo: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 出荷先
    shippingSelected: "",
    // 取引先
    suppliersSelected: "",
    // 発行条件(送り状)
    issueTermsSelected: "",
    // 発行条件(荷札)
    tagTermsSelected: "",
    // メニュー
    openMenu: null,
    // 出荷予定Fromカレンダー
    dateMenuFrom: false,
    //届け先
    destinationDisplay: "",
    //届け先名style
    destinatStyle: "display: none",
    // 出荷予定Toカレンダー
    dateMenuTo: false,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    // チェックボックスダイアログ
    selectDialogMsg: false,
    // 選択チェックメッセージダイアログ
    selectMessage: "",
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    //チェックした値たち
    checkLists: [],
    // チェックした品番
    checkproductCnList: [],
    // チェックしたlot
    checkLotList: [],
    // 出荷Sid
    checkToSid: [],
    // 出荷No
    checkShipNoList: [],
    // チェックした品質区分
    checkStatus: [],
    // チェックした出荷実績数
    checkShipAchieveQty: [],
    // outListSid
    outListSidList: [],
    // outListSubSid
    outListSubSidList: [],
    productCd: "",
    alertSupplierMessage: "",
    // 品番・品名
    ProdNameSelected: "",
    prodNameList: [],
    //出荷実績日
    shippingScheduleDateJ: [],
    // ヘッダ
    headerItems: [
      { text: "選択", value: "check", width: "3%", align: "center", sortable: false },
      // 出荷実績日
      {
        text: i18n.tc("出荷実績日"),
        value: "shippingScheduleDateJ",
        width: "6%",
        align: "center",
        sortable: true,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_shippingTo"),
        value: "shippingTo",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "shipNo",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // 品番品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "15%",
        align: "left",
        sortable: false,
      },
      //ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "16%",
        align: "left",
      },
      // 品質区分
      {
        text: i18n.tc("label.lbl_arrivalStatus"),
        value: "status",
        width: "10%",
        align: "center",
        sortable: false,
      },
      // 出荷実績数
      {
        text: i18n.tc("label.lbl_shipAchieveQty"),
        value: "shipAchieveQty",
        width: "7%",
        align: "right",
      },
      // 管理No.
      {
        text: i18n.tc("label.lbl_managementNo"),
        value: "managementNo",
        width: "13%",
        align: "center",
      },
    ],
    // 修正削除画面
    detailDialogData: {},
    // 総件数
    totalCount: 0,
    // 一覧
    inputList: [],
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shippingList: [],
    // 発行条件プルダウン
    issueTermsList: [],
    // 品番品名プルダウン
    productCnCdList: [],
    individualList: [],
    productCnCdSelected: "",
    productList: [],
    // 出荷No
    txt_slipNo: "",
    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    // 輸送会社名（登録用）
    truck: "",
    //親画面に渡す値
    emitData: {},
    // 取引先名＋取引先コード
    suppliersNm: "",
    // 出荷先名+コード
    shippingNm: "",
    // 品番コードApi検索用
    productCode: "",
    shippingSid: "",
    // 初期表示時初期化
    openFlag: "",
    toSid: "",
    search: "",

    // 入力チェック
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      supplierRequired: (value) => !!value || "取引先を入力してください",

      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },
    getSuppliersSelected: "",
    productHintArrival: "",
  }),

  created() {
    this.suppliersSelected = this.$route.params.searchSuppliersSelected;
    this.shippingSelected = this.$route.params.searchShippingSelected;
    // this.shippingScheduleDateFrom = this.$route.params.searchShippingScheduleDateFrom;
    // this.shippingScheduleDateFromCal = this.$route.params.searchShippingScheduleDateFromCal;
    // this.shippingScheduleDateTo = this.$route.params.searchShippingScheduleDateTo;
    // this.shippingScheduleDateToCal = this.$route.params.searchShippingScheduleDateToCal;
    this.issueTermsSelected = this.$route.params.searchIssueTermsSelected;
    this.tagTermsSelected = this.$route.params.searchTagTermsSelected;
    this.truckNm = this.$route.params.searchTruckNm;
    if (this.suppliersSelected) {
      this.getApiList();
    }
  },

  methods: {
    emitSelect() {
      // チェック済出荷Noリスト
      const noList = [];
      // チェック済み品番リスト
      const productCdList = [];
      // チェック済み品番リスト
      const productNameList = [];
      //チェック済みlotリスト
      const lotlist = [];
      // チェック済みtoSid
      const toSidList = [];
      // チェック済みshipNo
      const shipNoList = [];
      // チェック済みのoutListSid
      const outListSidList = [];
      // チェック済みのoutListSubSidList
      const outListSubSidList = [];
      //チェック済み品質区分リスト
      const statusList = [];
      //チェック済み出荷実績数リスト
      const shipAchieveQtyList = [];
      //チェック済み操作履歴SIDリスト
      const opeHistsid = [];
      //チェック済みロケーションSIDリスト
      const locationsid = [];
      const managementno = [];
      //チェック済み出荷実績日リスト
      const shippingScheduleDateJList = [];

      var onCheckoutListSid = "";
      var onCheckoutListSubSid = "";
      var onCheckName = "";
      var onCheckproductCn = "";
      var onCheckproductName = "";
      var onCheckLot = "";
      var onCheckToSid = "";
      var onCheckShipNo = "";
      var onCheckStatus = "";
      var onCheckShipAchieveQty = "";
      var opeHistSid = "";
      var locationSid = "";
      var managementNo = "";
      var onCheckshippingScheduleDateJ = "";
      // 日付比較用のリスト
      var dateList = [];
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          // 未承認データのチェック
          if (this.inputList[i].approvalFlg == "1") {
            this.selectDialogMsg = true;
            this.selectMessage = messsageUtil.getMessage("P-RTN-001_003_E");
            return;
          }
          onCheckoutListSid = this.inputList[i].outListSid;
          onCheckoutListSubSid = this.inputList[i].outListSubSid;
          onCheckName = this.inputList[i].lot;
          onCheckproductCn = this.inputList[i].productCd;
          onCheckproductName = this.inputList[i].productNm;
          onCheckLot = this.inputList[i].lot;
          onCheckToSid = this.inputList[i].toSid;
          onCheckShipNo = this.inputList[i].shipNo;
          var date = this.inputList[i].shippingScheduleDateJ;
          onCheckStatus = this.inputList[i].status;
          onCheckShipAchieveQty = this.inputList[i].shipAchieveQty;
          opeHistSid = this.inputList[i].opeHistSid;
          locationSid = this.inputList[i].locationSid;
          managementNo = this.inputList[i].managementNo;
          onCheckshippingScheduleDateJ = this.inputList[i].shippingScheduleDateJ;
          noList.push(onCheckName);
          productCdList.push(onCheckproductCn);
          productNameList.push(onCheckproductName);
          lotlist.push(onCheckLot);
          toSidList.push(onCheckToSid);
          dateList.push(date);
          shipNoList.push(onCheckShipNo);
          outListSidList.push(onCheckoutListSid);
          outListSubSidList.push(onCheckoutListSubSid);
          statusList.push(onCheckStatus);
          shipAchieveQtyList.push(onCheckShipAchieveQty);
          opeHistsid.push(opeHistSid);
          locationsid.push(locationSid);
          managementno.push(managementNo);
          shippingScheduleDateJList.push(onCheckshippingScheduleDateJ);
        }
      }
      // 取引先名と取引先コードを取得
      for (var l = 0; l < this.supplierList.length; l++) {
        if (this.supplierList[l].value == this.searchedSuppliers) {
          this.suppliersNm = this.supplierList[l].text;
        }
      }
      // 出荷先名とコードを取得
      for (let i = 0; i < this.shippingList.length; i++) {
        if (this.shippingSelected == this.shippingList[i].value) {
          this.shippingNm = this.shippingList[i].text;
        }
      }
      this.checkLists = noList;
      this.checkproductCnList = productCdList;
      this.productNameList = productNameList;
      this.checkLotList = lotlist;
      this.checkToSid = toSidList;
      this.checkShipNoList = shipNoList;
      this.outListSidList = outListSidList;
      this.outListSubSidList = outListSubSidList;
      this.checkStatus = statusList;
      this.shipAchieveQtyList = shipAchieveQtyList;
      this.opeHistSid = opeHistsid;
      this.locationSid = locationsid;
      this.managementNo = managementno;
      this.shippingScheduleDateJ = shippingScheduleDateJList;
      this.emitData = {
        clientName: this.checkLists,
        productCn: this.checkproductCnList,
        productNameList: this.productNameList,
        lot: this.checkLotList,

        outListSid: this.outListSidList,
        outListSubSid: this.outListSubSidList,
        shipAchieveQty: this.shipAchieveQtyList,
        locationSid: this.locationSid,
        opeHistSid: this.opeHistSid,
        officeSid: this.officeSid,
        managementNo: this.managementNo,
        shippingScheduleDateJ: this.shippingScheduleDateJ,
        // 取引先SID(API登録用)
        suppliersNm: this.searchedSuppliers,
        // 取引先名とコード合体(画面表示用)
        suppliersSelected: this.suppliersNm,
        // 出荷先SID
        shippingNm: this.shippingSelected,
        // 出荷先画面表示用
        shippingSelected: this.shippingNm,
        toSid: this.checkToSid[0],
        shipNo: this.checkShipNoList[0],
        // 出荷品質区分画面表示用
        status: this.checkStatus[0],
      };

      // this.emitData.push(this.suppliersSelected, this.shippingSelected);
      this.$emit("emitSelect", this.emitData);
      this.clearAll();
    },
    link() {
      location.href = this.returnURL;
    },
    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.prodNameList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.prodNameList = this.prodNameList.filter((v) => v.value == value);
    },
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getApiList(false, false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false, false);
    },
    // 品番セット
    setProducts() {
      for (var i = 0; i < this.prodNameList.length; i++) {
        if (this.prodNameList[i].value == this.ProdNameSelected) {
          this.productCode = this.prodNameList[i].code;
          return this.prodNameList[i].name;
        }
      }
    },
    // 品番のcodeを取得
    changeProductCd() {
      for (var i = 0; i < this.productList.length; i++) {
        if (this.productList[i].value == this.productCnCdSelected) {
          this.productCode = this.productList[i].code;
          // alert(this.productList[i].code);
        }
      }
    },
    /**
     * チェックボックス押下処理
     */
    checkList(item, event) {
      //チェックされているものがあるかの判定
      //あった場合、既にチェックされている値と同じか
      if (!item.check) {
        // チェックを入れた場合
        if (this.chengeListCheckbox[0]) {
          // 0番が存在する場合
          if (this.chengeListCheckbox[0].shipNo !== item.shipNo) {
            // 0番目が同じでない場合
            event.target.checked = false;
          } else {
            this.chengeListCheckbox.push(item);
          }
        } else {
          // 編集用リスト
          this.chengeListCheckbox.push(item);
          // 画面遷移用リスト
          this.listCheckboxBtn.push(item);
        }
      } else {
        // チェックを外した場合
        var indexs = this.chengeListCheckbox.indexOf(item);
        this.chengeListCheckbox.splice(indexs, 1);
        this.listCheckboxBtn = [];
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      //個人コード判別用情報
      const individualCodeList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_INDIVIDUAL_DIV);
      Promise.all([clientList, individualCodeList])

        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          this.individualList = result[1];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 全削除処理
    clearAll() {
      this.shippingSelected = "";
      this.suppliersSelected = "";
      this.shippingScheduleDate = "";
      this.truckNm = "";
      this.totalCount = 0;
      this.inputList = [];
      this.page = 1;
      this.pageCount = 0;
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.chengeListCheckbox.splice(0);
      this.alertSupplierMessage = "";
      this.prodNameList = [];
      this.ProdNameSelected = "";
      this.txt_slipNo = "";
      this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
    },
    /**
     * 商品コードリストボックス取得処理
     */
    checkProductCd(value) {
      for (var i = 0; i < this.productList.length; i++) {
        if (this.productList[i].value == value.productCd) {
          return this.productList[i].name;
        }
      }
    },
    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;

      // 画面表示用
      this.truckNm = this.truckInfo.carrierCompName;
      // API検索用
      this.truckKbn = this.truckInfo.carrierCompCd;
      this.truck = this.truckInfo.deliveryTraderSid;
      this.isOpenTruck = false;
    },
    closeDialog() {
      this.$emit("closePopup");
      this.clearAll();
      this.isOpenTruck = false;
    },
    okClicked() {
      this.selectDialogMsg = false;
    },

    /**
     * 出荷実績一覧画面： 検索ボタン押下処理
     */
    getApiList(checkBoxFlag, isSearchConditionUpdate) {
      if (this.$refs.form.validate()) {
        this.alertSupplierMessage = "";
        // 取引先存在チェック
        if (this.suppliersSelected == "" || this.suppliersSelected == null) {
          this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
          return;
        }
        if (
          // FromToの日付、入力チェック
          new Date(this.shippingScheduleDateFrom).getTime() >
          new Date(this.shippingScheduleDateTo).getTime()
        ) {
          this.alertMessage = i18n.tc("check.chk_inputFromVali");
        } else {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";
          // 初期検索かページング検索か判断
          if (checkBoxFlag) {
            this.isAllSelected = false;
          }

          // ローディング画面表示ON
          this.loadingCounter = 1;
          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();
          // 営業所SID（ログインユーザ情報）
          config.params.officeSid = sessionStorage.getItem("sales_office_sid");

          // 取引先SID
          config.params.clientSid = this.suppliersSelected;
          // 品番
          if (this.ProdNameSelected) {
            config.params.itemCd = this.productCode;
          }
          // config.params.itemCd = "";
          // 出荷No
          if (this.txt_slipNo) {
            config.params.outListNo = this.txt_slipNo;
          }
          // 出荷先
          if (this.shippingSelected) {
            // ToSID（出荷先SID）
            config.params.toSid = this.shippingSelected;
          }
          //届け先名
          if (this.destinationDisplay != "") {
            config.params.shipToName = this.destinationDisplay;
          }

          // From日
          config.params.actualDateFrom = dateTimeHelper.convertUTC(this.shippingScheduleDateFrom);
          // To日
          var arr = this.shippingScheduleDateTo + " 23:59:59.999";
          config.params.actualDateTo = dateTimeHelper.convertUTC(arr);
          // ページャー処理
          if ((this.page >= 2 && this.inputList.length == 0) || isSearchConditionUpdate) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;
          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_RETURNSEARCH, config)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  const list = [];
                  // 出荷予定リストを取得
                  jsonData.resIdv.returnsSearch.forEach((row) => {
                    let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                    list.push({
                      // 出荷日（API登録用=UTC）
                      shippingScheduleDate: row.outActualDate,
                      // 出荷日（画面表示用=JST:秒数なし）
                      shippingScheduleDateJ: row.outActualDate,
                      // 品番
                      productCd: row.itemCd,
                      // 品番
                      itemCd: row.itemCd.substring(0, 50).trimRight(),
                      // 付帯
                      incidental: row.itemCd.slice(-50).trimRight(),
                      // 品名
                      productNm: row.itemName,
                      // 出荷実績数
                      shipAchieveQty: commonUtil.formatToCurrency(Number(row.outStock)),
                      // ロット(100桁)
                      lot: row.lotNo,
                      // ロット + シリアル
                      lotNo: lotNoObj.lot,
                      //期限日 + 付属キー１ + 付属キー２
                      dueDate: lotNoObj.dueDate,
                      // 出荷SID
                      outListSid: row.outListSid,
                      // 出荷No
                      shipNo: row.outListNo,
                      // 出荷先
                      shippingNm: row.toName,
                      toSid: row.toSid,
                      outListSubSid: row.outListSubSid,
                      // 品質区分
                      statusDiv: row.qualityDiv,
                      // 品質区分名
                      status: row.qualityDivName,
                      //管理No.
                      managementNo: row.itemManageNo,
                      //出荷先
                      shippingTo: row.shipToName,
                      //出荷先+住所
                      shipToAddress: row.shipToAddress,
                      // 承認フラグ
                      approvalFlg: row.returnsNotApprovalFlg,
                      //操作履歴SID
                      opeHistSid: row.opeHistSid,
                      //ロケーションSID
                      locationSid: row.locationSid,
                    });
                  });
                  this.inputList = list;

                  // ページング検索の場合の全選択チェックボックス処理
                  if (this.isAllSelected) {
                    this.isAllSelected = true;
                    for (var i = 0; i < this.inputList.length; i++) {
                      this.inputList[i].check = true;
                    }
                  }
                  resolve(response);
                  // 検索値保持
                  this.searchedSuppliers = this.suppliersSelected; // 取引先SID
                  this.officeSid = config.params.officeSid; //営業所SID
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },
    // /**
    //  * 仮データ作成処理：検索ボタン押下時:API出来次第削除
    //  */
    // getDummyApiList(checkBoxFlag) {
    //   if (
    //     // FromToの日付、入力チェック
    //     new Date(this.shippingScheduleDateFrom).getTime() >
    //     new Date(this.shippingScheduleDateTo).getTime()
    //   ) {
    //     this.alertMessage = i18n.tc("check.chk_fromToDate");
    //   } else {
    //     // バリデーションエラーメッセージを非表示
    //     this.alertMessage = "";
    //     // 初期検索かページング検索か判断
    //     if (checkBoxFlag) {
    //       this.isAllSelected = false;
    //     }

    //     const dummyJsonData = [
    //       {
    //         shippingScheduleDateJ: "2022/04/01",
    //         lot: "lot0000",
    //         shipAchieveQty: "300",
    //         outListNo: "00000",
    //         productCd: "205/40 R16 83V NTNEO TLY GSS RD2X",
    //       },
    //       {
    //         shippingScheduleDateJ: "2022/05/12",
    //         lot: "lot",
    //         shipAchieveQty: "120",
    //         outListNo: "00000",
    //         productCd: "265/70 R17 115T OPAT3 TLR SS WO",
    //       },
    //       {
    //         shippingScheduleDateJ: "2022/05/12",
    //         lot: "lot",
    //         shipAchieveQty: "120",
    //         outListNo: "00000",
    //         productCd: "285/50 R20 116S NTGA2 TLR SS BECG",
    //       },
    //       {
    //         shippingScheduleDateJ: "2022/04/01",
    //         lot: "lotzz",
    //         shipAchieveQty: "300",
    //         outListNo: "0101",
    //         productCd: "205/40 R16 83V NTNEO TLY GSS RD2X",
    //       },
    //       {
    //         shippingScheduleDateJ: "2022/04/01",
    //         lot: "lotzz",
    //         shipAchieveQty: "300",
    //         outListNo: "0101",
    //         productCd: "205/40 R16 83V NTNEO TLY GSS RD2X",
    //       },
    //     ];
    //     const dummyJson = JSON.parse(JSON.stringify(dummyJsonData));
    //     const list = [];

    //     // 出荷予定リストを取得
    //     dummyJson.forEach((row) => {
    //       list.push({
    //         // 出荷日（API登録用=UTC）
    //         // shippingScheduleDate: row.outScheduleDate,
    //         // 出荷日（画面表示用=JST:秒数なし）
    //         // shippingScheduleDateJ: dateTimeHelper.convertUTC2JST(row.outScheduleDate).substr(0, 10),
    //         //出荷日
    //         shippingScheduleDateJ: row.shippingScheduleDateJ,
    //         // 出荷SID
    //         shipSid: row.outListSid,
    //         // 出荷No
    //         shipNo: row.outListNo,
    //         //ロット
    //         lot: row.lot,
    //         // 出荷先
    //         shippingNm: row.toName,
    //         // 出荷実績数
    //         shipAchieveQty: row.shipAchieveQty,
    //         // 品番品名
    //         productCd: row.productCd,
    //         productNm: row.productNm,
    //       });
    //     });
    //     this.inputList = list;
    //   }
    // },
    /**
     * 出荷予定一覧画面： 荷札発行API TODO:画面作成が必要な為
     */
    getTagIssue() {
      if (
        // FromToの日付、入力チェック
        new Date(this.shippingScheduleDateFrom).getTime() >
        new Date(this.shippingScheduleDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_input");
      } else {
        if (this.$refs.form.validate()) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";

          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();

          // チェック済出荷Noリスト
          const noList = [];
          var onCheckNo = "";
          for (var i = 0; i < this.inputList.length; i++) {
            if (this.inputList[i].check) {
              onCheckNo = this.inputList[i].shipNo;
              noList.push(onCheckNo);
            }
          }
          config.params.outListSids = noList;

          // console.debug("getTagIssue() Config", config);
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_TAGLABEL, config)
              .then((response) => {
                // console.debug("getTagIssue() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                  this.returnURL = jsonData.resIdv.filePath;
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                console.error("getTagIssue() Resolve", resolve);

                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },

    /**
     * 初期値
     */
    init() {
      if (this.isOpenprop == true) {
        alert("isOpenの初期表示");
        this.shippingSelected = "";
        this.suppliersSelected = "";
        this.shippingScheduleDate = "";
        this.truckNm = "";
        this.ProdNameSelected = "";
        this.prodNameList = [];
        this.inputList = [];
        this.itemsPerPage = 10;
        this.itemsPerPageBtn = "10";
        this.shippingScheduleDateTo = dateTimeHelper.convertJST();
        this.shippingScheduleDateFrom = dateTimeHelper.convertJST();
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      } else {
        this.shippingSelected = "";
        this.suppliersSelected = "";
        this.shippingScheduleDate = "";
        this.ProdNameSelected = "";
        this.prodNameList = [];

        this.truckNm = "";
        this.inputList = [];
        this.itemsPerPage = 10;
        this.itemsPerPageBtn = "10";
        this.shippingScheduleDateTo = dateTimeHelper.convertJST();
        this.shippingScheduleDateFrom = dateTimeHelper.convertJST();
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }
      this.getCustomInfo();
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 出荷予定一覧画面：配送先選択ボタン処理
     */
    openTruck() {
      this.isOpenTruck = true;
    },

    /**
     * 出荷日付Fromを－１日します。
     */
    prevDate() {
      let date = new Date(this.shippingScheduleDateFromCal);
      this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 出荷日付Fromを＋１日します。
     */
    nextDate() {
      let date = new Date(this.shippingScheduleDateFromCal);
      this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * 出荷日付Fromを－１日します。
     */
    prevToDate() {
      let date = new Date(this.shippingScheduleDateToCal);
      this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },

    /**
     * 出荷日付Fromを＋１日します。
     */
    nextToDate() {
      let date = new Date(this.shippingScheduleDateToCal);
      this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shippingScheduleDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shippingScheduleDateToCal = null;
      }
    },

    /**
     * 出荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 出荷予定登録画面：出荷元コードリストボックス取得処理
     */
    setSrhArrival() {
      for (var i = 0; i < this.shippingList.length; i++) {
        if (this.shippingList[i].value == this.shippingSelected) {
          return this.shippingList[i].name;
        }
      }
    },

    /**
     * 出荷予定登録画面：チェックボックス取得処理
     */
    onInputCheck(item) {
      if (item.check) {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].value = true;
        }
      } else {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = false;
          this.inputList[i].value = false;
        }
      }
    },

    /**
     * 発行条件リストボックス変換
     */
    setTerms() {
      for (var i = 0; i < this.issueTermsList.length; i++) {
        if (this.issueTermsList[i].value == this.issueTermsSelected) {
          return this.issueTermsList[i].name;
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.shippingList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.displayText;
        this.getSuppliersSelected = val.displayText;
      }
      this.shippingList = this.shippingList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }
      //個人宅用処理
      if (this.shippingSelected != null) {
        for (var i = 0; i < this.shippingList.length; i++) {
          for (var b = 0; b < this.individualList.length; b++) {
            if (this.shippingList[i].value == this.shippingSelected) {
              if (this.shippingList[i].baseCd == this.individualList[b].text) {
                this.destinatStyle = "display: inline-flex";
                return;
              }
            }
          }
        }
      }
      this.destinatStyle = "display: none";
      this.destinationDisplay = "";
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    isOpenprop: function (newValue) {
      if (newValue) {
        alert("値の受け渡し");
        this.init();
      }
    },
    shippingScheduleDateFromCal(val) {
      this.shippingScheduleDateFrom = this.formatDate(val);
    },
    shippingScheduleDateToCal(val) {
      this.shippingScheduleDateTo = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false, false);
    },
    search(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.prodNameList = list.concat(result[0]);
              this.prodNameList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.prodNameList.length = 0;
        }
      }
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          if (sortBy == "shipNo") {
            sortBy = "outListNo";
          } else if (sortBy == "shippingScheduleDate") {
            sortBy = "outScheduleDate";
          }
          this.sortItem = sortBy + " " + ascdesc;
          this.getApiList();
        }
      },
      deep: true,
    },
    suppliersSelected() {
      // 出荷先リスト/品番/品名クリア
      this.shippingList = [];
      this.shippingSelected = "";
      this.prodNameList = [];
      this.ProdNameSelected = "";
    },
    // 出荷先取得
    getSuppliersSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.shippingList = [];
          this.shippingSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          let officeCd = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          // To（出荷先）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            officeCd,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shippingList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.shippingList.length = 0;
          this.shippingList = [];
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

.link {
  text-decoration: none;
}

.search-textbox-remark {
  width: 68rem;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 8px;
}
</style>
